import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push, set } from 'firebase/database';
import { HashRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css'; 
import img1 from './images/img1.png';
import img2 from './images/img2.png';
import logo from './images/logo.jpeg';


const doctors = [
  { id: 1, name: 'მამუკა მგელიაშვილი', specialty: 'გინეკოლოგი' },
  { id: 2, name: 'მაია ჭეიშვილი', specialty: 'ენდოკრინოლოგი' },
];

function Home() {
  const sliderSettings = {
    dots: true,
  infinite: true,
  speed: 500, // Reduced speed for smoother transitions
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  centerMode: true,
  centerPadding: '0',
  autoplaySpeed: 3000, // Reduced autoplay speed
  };

  const [searchTerm, setSearchTerm] = useState('');

  const filteredDoctors = doctors.filter(
    (doctor) =>
      doctor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      doctor.specialty.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="home-container">
      <h1>სამედიცინო ცენტრი მმ</h1>
      <Helmet>
      <title>MM Clinic - მმ კლინიკა</title>
      <meta name="description" content="ჩვენი პროფესიონალი სამედიცინო პერსონალი 9 საათიდან 6 საათამდე მზადაა პაციენტთა მისაღებად და ეფექტური მკურნალობის შედეგად, 
      მათ ჩვეულ ცხოვრების რიტმში დასაბრუნებლად."/>
      <meta name='keywords' content="MM Clinic, კლინიკა, ჯანმრთელობა, სამედიცინო სამსახური, მამუკა მგელიაშვილი, მაია ჭეიშვილი, გინეკოლოგი, ენდოკრინოლოგი,ტიციან ტაბიძის 43, ვაკე კლინიკა, ქალთა ჯანმრთელობა, MM CLINIC, mm clinic,მმ კლინიკა,MM Clinic Medical Services,Endocrinology Care Georgia,Best Gynecologist in Tbilisi,Titsian Tabidze Street Clinic
      Hormone Specialist Tbilisi,Georgia Gynecological Care,Women's Health Services Tbilisi,Maia Cheishvili Endocrinology,Mamuka Mgeliashvili Gynecology,MM Clinic Tbilisi,MM Clinic Georgia"/>
      <meta property="og:title" content="MM Clinic - მმ კლინიკა" />
      <meta property="og:description" content="მ.მ კლინიკა თქვენი ჯანმრთელობის სანდო ცენტრი თბილისში" />
      <meta property="og:image" content={logo} />
      <meta name="twitter:title" content="MM Clinic - მმ კლინიკა" />
      <meta name="twitter:description" content="მ.მ კლინიკა თქვენი ჯანმრთელობის სანდო ცენტრი თბილისში" />
      <meta name="twitter:image" content={logo} />
      </Helmet>
      {/* Slider component */}
      <Slider {...sliderSettings}>
        <div>
          <img className='img1' src={img1} alt="Image1" />
        </div>
        <div>
          <img className='img2' src={img2} alt="Image2" />
        </div>
      </Slider>

      {/* Search bar */}
      <input
        type="text"
        placeholder="მოძებნეთ ექიმი სახელის, გვარის ან სპეციალობის მიხედვით"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Display filtered doctors if search term is entered */}
      {searchTerm && (
        <div className="doctors-list">
          {filteredDoctors.map((doctor) => (
            <div key={doctor.id} className="doctor-card">
              <h3>{doctor.name}</h3>
              <p>{doctor.specialty}</p>
            </div>
          ))}
        </div>
      )}

      {/* Welcome text */}
      <div className="welcome-text">
      </div>
    </div>
  );
}

function Services() {
  return <div>სერვისები</div>;
}

function About() {
  return <div> <b>"მ.მ კლინიკა თქვენი ჯანმრთელობის სანდო ცენტრი თბილისში"</b>

  <p>კეთილი იყოს თქვენი მობრძანება <b>სამედიცინო ცენტრ მმ-ში,</b>, რომელიც მდებარეობს ვაკეში, ტიციან ტაბიძის 43 ნომერში.<br></br></p>
  
  <p>
  პაციენტებისადმი პროფესიული მიდგომა, მაღალინფორმატიული კვლევის მეთოდების გამოყენება და კვალიფიციური მკურნალობა არის ჩვენი სამედიცინო ცენტრის სავიზიტო ბარათი 
  და ეს ყველაფერი თბილ, ოჯახურ ატმოსფეროში. მრავალწლიანი გამოცდილების მქონე, პროფესიონალი სამედიცინო პერსონალი 9 საათიდან 6 საათამდე მზადაა პაციენტთა მისაღებად და ეფექტური მკურნალობის შედეგად,
  მათ ჩვეულ ცხოვრების რიტმში დასაბრუნებლად.
</p><br></br>

      <h3><b>მამუკა მგელიაშვილი</b> - მეან გინეკოლოგი:</h3>
      <img src={img1} alt="გინეკოლოგი მამუკა მგელიაშვილი ფოტო" />

  
   <h3><b>მაია ჭეიშვილი</b> - ენდოკრინოლოგი:</h3>
 

    <img src={img2} alt='მაია ჭეიშვილი ენდოკრინოლოგი'></img>
  
  

  
  
  </div>;
}

function Contact() {

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    message: '',
  });

  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyDvX6m69Xet9FN9s2O_UO1c28yb5NRyjbY",
    authDomain: "authentication-app-8bc55.firebaseapp.com",
    databaseURL: "https://authentication-app-8bc55-default-rtdb.firebaseio.com",
    projectId: "authentication-app-8bc55",
    storageBucket: "authentication-app-8bc55.appspot.com",
    messagingSenderId: "960201488864",
    appId: "1:960201488864:web:a0dd9b9d8671ff2ecbd385"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  

  const handleSendMessage = async (e) => {
    e.preventDefault();

    const { username, email, message } = formData;

    // Check if all fields are filled in
    if (username && email && message) {
      try {
        // Store the message in the database with the author's email
        const messageRef = push(ref(database, 'messages'));
        await set(messageRef, {
          username: username,
          email: email,
          message: message,
          timestamp: new Date().toString(),
        });

        console.log('Message sent successfully');
        alert('თქვენი შეტყობინება გაიგზავნა!');
      } catch (error) {
        const errorMessage = error.message;
        console.error('Send message error:', errorMessage);
        alert(errorMessage);
      }
    } else {
      alert('გთხოვთ შეავსოთ ყველა მოცემული გრაფა');
    }
  };

  return (
    <div id="login-box">
      <input
        type="text"
        id="username"
        name="username"
        placeholder="თქვენი სახელი და გვარი"
        value={formData.username}
        onChange={handleInputChange}
      />
      <input
        type="email"
        id="email"
        name="email"
        placeholder="თქვენი იმეილი"
        value={formData.email}
        onChange={handleInputChange}
      />
      <textarea
  id="message"
  name="message"
  placeholder="რით შეგვიძლია დაგეხმაროთ?"
  value={formData.message}
  onChange={handleInputChange}
></textarea>
      <input
        type="submit"
        id="sendMessage"
        name="sendMessage"
        value="გაგზავნა"
        onClick={handleSendMessage}
      />
    </div>
  );
}




function App() {

  return (
    <Router>
      <div className="app-container">
        <div className="navigation">
          <NavLink to={"/home"}>
        <img  src={logo} alt="Logo" className="logo" />
          </NavLink>
          <NavLink to="/home" activeClassName="active" exact>
          <i className="fa-solid fa-house-chimney-medical"></i>
          მთავარი
          </NavLink>
          <NavLink to="/services" activeClassName="active">
          <i className="fa-solid fa-stethoscope"></i>
          სერვისები
          </NavLink>
          <NavLink to="/about" activeClassName="active">
          <i className="fa-solid fa-people-group"></i>
          ჩვენს შესახებ
          </NavLink>
          <NavLink to="/contact" activeClassName="active">
          <i className="fa-solid fa-phone"></i>
          კონტაქტი
          </NavLink>
        </div>

        <div className="page-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
